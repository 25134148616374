export const metadataSelectionOptions = [
  {value:'authorial_id',label:'Autorské ID'},
  {value:'label',label:'Označení'},
  {value:'type',label:'Typ'},
  {value:'created',label:'Vytvořeno'},
  {value:'updated',label:'Upraveno'},
  {value:'aip_state',label:'Stav AIP'},
  {value:'producer_name',label:'Jméno dodavatele'},
  {value:'user_name',label:'Jméno zodpovědné osoby'},
  {value:'sip_id',label:'AIP ID'},
  {value:'producer_profile',label:'Externí ID profilu dodavatele'},
  {value:'sip_profile',label:'Externí ID SIP profilu'},
  {value:'validation_profile',label:'Externí ID validačního profilu'},
  {value:'workflow_definition',label:'Externí ID definice workflow'},
  {value:'sip_version_number',label:'Číslo verze SIP'},
  {value:'sip_version_of',label:'ID předchozí verze SIP'},
  {value:'xml_version_number',label:'Číslo XML verze'},
  {value:'xml_version_of',label:'ID předchozí verze XML'},
  {value:'latest',label:'Poslední verze'},
  {value:'dublin_core',label:'Popisná metadata'},
  {value:'arc_event',label:'Provenance přebrané ze SIP'},
  {value:'creating_application',label:'Vytvářející aplikace'},
  {value:'identified_format',label:'Identifikované formáty'},
  {value:'img_metadata',label:'Metadata z pořízení snímku'},
  {value:'premis_event',label:'Provenance a kontroly při ingestu'},
  {value:'extracted_format',label:'Formáty'},
  {value:'DC_EXPORT_NDK_PUBLICATION_DATE',label:'NDK: Datum vydání'},
  {value:'DC_EXPORT_NDK_NUMBER',label:'NDK: Číslo vydání/části'},
  {value:'DC_EXPORT_NDK_AUTHOR',label:'NDK: Autor'},
  {value:'DC_EXPORT_NDK_ISBN',label:'NDK: ISBN'},
  {value:'DC_EXPORT_NDK_ISSN',label:'NDK: ISSN'},
  {value:'DC_EXPORT_NDK_ISMN',label:'NDK: ISMN'},
  {value:'DC_EXPORT_NDK_CNB',label:'NDK: CNB'},
  {value:'DC_EXPORT_NDK_URNNBN',label:'NDK: URNNBN'},
  {value:'DC_EXPORT_NDK_UUID',label:'NDK: UUID'},
  {value:'DC_EXPORT_NDK_BARCODE',label:'NDK: BARCODE'},
  {value:'DC_EXPORT_NDK_SIGNATURE',label:'NDK: Signatura'},
  {value:'DC_EXPORT_NDK_SIGLA',label:'NDK: Sigla'}
];